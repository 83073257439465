import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
//import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
//import ComponentTextblock1 from "../../components/component-textblock-1";
import FooterCta from "../../components/footer-cta";

import { useIntl, Link } from "gatsby-plugin-react-intl";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const PageCompanIndex = ({ data, location }) => {
  const intl = useIntl();
  return (
    <Layout location={location}>
      <Seo
        title={intl.locale === "en" ? `` : ``}
        description={intl.locale === "en" ? `` : ``}
      />
      <section className="sect sect_PageMission_catch sect-bg-bls">
        <div className="container">
          <div className="flex-row justify-content-between">
            <div className="flex-column-7">
              <h2 className="h3">
                Creator of the Future. Our Society emerges to where physical
                space and cyber space are often overlapped. Cybernics technology
                helps us uniting Humans, Robotics, and Information system.
              </h2>
              <p className="message-sm">
                Prof. Dr. Yoshiyuki SANKAI earned a Ph.D. in Engineering from
                University of Tsukuba in Japan in 1987. He has progressed from
                being a research fellow at the JSPS to assistant professor,
                associate professor, and then professor at the Graduate School
                of Systems and Information Engineering, University of Tsukuba.
                Dr. Sankai was also a visiting professor at Baylor College of
                Medicine in Houston, Texas in the United States. Currently, he
                is a professor, the Executive Research Director at the Center
                for Cybernics Research and the Director at the F-MIRAI,
                University of Tsukuba; and the President and CEO of CYBERDYNE
                Inc.
              </p>
              <p className="message-sm">
                He successively held the position of Leader/PM for the FIRST
                program and ImPACT program, both initiated by the Japanese
                Cabinet Office, and the Business producer of the Program for
                Building Regional Innovation Ecosystem by MEXT. He also served
                as an Executive Board Member of Advanced Robotics, and a member
                of Global Agenda Council on Robotics & Smart Devices of the WEF.
                Now, he is an Executive Board Member of the Japan Society of
                Embolus Detection and Treatment, and a fellow of the Robotics
                Society of Japan (“RSJ”) and the Society of Instrument and
                Control Engineers (“SICE”). He is a Center Partner of the Center
                for the Fourth Industrial Revolution, a member of Global Future
                Council of Production, and a member of Global Precision Medicine
                Council of the WEF. And he is also appointed as an International
                Fellow of the Royal Swedish Academy of Engineering
                Sciences(“IVA”).
              </p>
            </div>

            <div className="flex-column-4">
              <StaticImage
                src="../../images/about/image-about-us-1.png"
                className="mb-4"
                alt=""
              />
              <p className="message-label-bold txt txt-rot">
                Prof. Dr. Yoshiyuki SANKAI
              </p>
              <p>President and CEO of Cyberdyne Inc.</p>
            </div>
          </div>

          <div>
            <p className="message-lg-gray font-exo py-7">
              The company name is derived from its utilization of innovative
              “Cybernics*” technologies and the suffix “dyne” which is derived
              from the Greek word dunamis meaning force/power.
            </p>
            <div className="flex-row">
              <div className="flex-column-7">
                <h2 className="h3">
                  Main awards associated with Prof. Dr. Yoshiyuki SANKAI
                </h2>
                <ul>
                  <li>
                    The 2005 World Technology Award (the Grand Prize) (2005),
                  </li>
                  <li>the Good Design Award (the Gold Prize) ,</li>
                  <li>
                    the Japan Innovator Award (the Award of Excellence) (2006),
                  </li>
                  <li>
                    the Minister of Economy, Trade and Industry Award (2007),
                  </li>
                  <li>
                    the 21st Century Invention Award at the National Invention
                    Awards, “Development of Cyborg-type Robot Technology”
                    (2009),
                  </li>
                  <li>the NetExplorateurs at UNESCO Headquarters (2011),</li>
                  <li>the Capek Award (2012),</li>
                  <li>
                    the 2014 Technology Pioneer at the World Economic Forum
                    (2013),
                  </li>
                  <li>the 2014 Edison Awards Gold Award,</li>
                  <li>the DealWatch Awards 2013,</li>
                  <li>the IPO of the Year at the Thomson Reuters (2014),</li>
                  <li>
                    the Minister of Education, Culture, Sports, Science and
                    Technology Award,
                  </li>
                  <li>the DealWatch Awards 2014,</li>
                  <li>
                    the Innovative Equity Deal of the Year at the Thomson
                    Reuters (2014),
                  </li>
                  <li>
                    the Minister of Economy, Trade and Industry Award, the
                    Zaikai Business Leader of the Year Award (2015),
                  </li>
                  <li>
                    the DIA Japan Outstanding Contribution to Health Award, the
                    Robot Award - Ministry of Health Labour and Welfare Award
                    (2016),
                  </li>
                  <li>
                    the Prime Ministers Award (the Nippon Venture Award) (2017),
                  </li>
                  <li>
                    the Ministry of Education, Culture, Sports, Science and
                    Technology Award (the Technology Management and Innovation
                    Award),
                  </li>
                  <li>
                    the Ichimura Prize in Industry, the Medal of Honor with
                    Purple Ribbon (2019),
                  </li>
                  <li>
                    the Intellectual Property Achievement Awards (the Minister
                    of Economy Trade and Industry Awards) (2020), etc.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-rot">
        <div className="container">
          <p className="h2 mb-5">
            {intl.locale === "en"
              ? `Learn more about us and the Cybernics Technology`
              : `Erfahren Sie mehr über uns und die Cybernics Technologie`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `About us` : `Über uns`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Our revolutionary technology is a game-changer of what we have
  as medical experts and neuro-rehabilitation professionals.`
                    : `Unsere revolutionäre Technologie ist bahnbrechend für medizinische Experten und Fachkräfte in der neurologischen Rehabilitation.`}
                </p>
                <Link to="/company/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Vision and Mission`
                    : `Vision und Auftrag`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `We envision a future where humans and technology are
  seamlessly interfaced, feeding off of each other.`
                    : `Wir stellen uns eine Zukunft vor, in der Menschen und Technologie nahtlos miteinander verbunden sind und sich gegenseitig unterstützen.`}
                </p>
                <Link to="/company/mission/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Cybernics</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `“Cybernics” (adjective: Cybernic) is a new academic field that
  is centered around cybernetics, mechatronics and informatics.`
                    : `“Cybernics" (Adjektiv: cybernic) ist ein neues akademisches Feld, das sich mit Kybernetik, Mechatronik und Informatik beschäftigt.`}
                </p>
                <Link to="/company/cybernics/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Contact/Access` : `Anfahrt`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Cyberdyne Care Robotics GmbH based in Bochum, North
                  Rhine-Westphalia. We welcome people from all over the world.
                </p>
                <div>
                  <Link to="/company/access/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <FooterCta />
    </Layout>
  );
};
export default PageCompanIndex;
